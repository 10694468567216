



import { Component, Vue } from "vue-property-decorator";
import { BASE_API_URL } from "../../../config";
import Axios from "axios";

import moment from "moment";
import saveAs from 'save-as';

@Component({ components: {  } })
export default class ScheduleJobs extends Vue {
    
    /**
     *  Public Variables
     */
    public scheduleJobs = [];
    public jobsLoader = null;
    public deletableJobs = [
        'SendMailForRequestOrderTitleReport',
        'sendMailToDisclosuresUsers',
    ];

    get loanTxnId() {
        return this.$route.query.id
    }

    /**
     * Public Functions
     */
    get scheduleJobsItems() {
        if (this.loanTxnId) {
            return this.scheduleJobs.filter(scheduleJob => {
                return scheduleJob.loanTxnId === this.loanTxnId
            });
        }

        return this.scheduleJobs;
    }

/**************************************************************************************************
*                                FOR GETTING THE LIST OF SCHEDULED JOBS                           *
***************************************************************************************************
 * Function Working Progress ---
 * Sending the  (GET) request into server 
 * and saving it to @var { scheduleJob } .
 **/
    public async fetchScheduleJobs(){
        this.jobsLoader = true;
        try {
            let response = await Axios.get(BASE_API_URL+'superAdmin/fetchScheduleJobs',{});
            this.scheduleJobs = response.data;
            this.jobsLoader = false;
        } catch (error) {
            console.log(error);
            this.jobsLoader = false;
        }
    }


    public async downloadScheduleJobs(){
        this.jobsLoader = true;
        try {
            let response = await Axios.get(BASE_API_URL+'superAdmin/downLoadCronsJobsList',{responseType:'blob'});
            saveAs(response.data, 'ListOfJobs.txt');
        } catch (error) {
            console.log(error);
            this.jobsLoader = false;
        }
    }

    public async deleteScheduleJob(body) {
        this.jobsLoader = true;

        try {
            await Axios.post(BASE_API_URL + 'superAdmin/deleteScheduleJob', body, { });
        } catch (error) {
            this.$snotify.error('Failed to delete the schedule job');
        }

        this.jobsLoader = false;
        this.fetchScheduleJobs();
    }

    public canDeleteJob(job) {
        return this.deletableJobs.includes(job.functionName);
    }

    /**
     * Mounted Function
     */
    mounted() {
        this.fetchScheduleJobs();
        this.downloadScheduleJobs();
    }
}
